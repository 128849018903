import React, { useState } from 'react'
import { Text, ActivityIndicator, PieGraph, SelectableItem, StackedBarGraph, BarGraph } from '../../generic'
import SummaryItemCard from '../../dashboard/SummaryItemCard'
import { SummaryItemsRow } from '../../dashboard/MyDashboard/MyDashboard.style'
import { Row, Col } from '../../generic/grid'
import { useQuery } from 'react-apollo'
import { MyFinancialHealthContainer, CardGrid, CardRow, Card, GraphContainer, ArrowContainer, TitleContainer, debtColors } from './MyFinancialHealth.style'
import { mokuChat } from '../../../images'
import { getRiskProfile, getAffordabilitySummary } from '../../../lib/financial'
import gql from 'graphql-tag'
import { VictoryScatter } from 'victory'
import CustomPoint from '../../dashboard/CustomPoint'
import theme from '../../../theme.style'
import { printCurrency } from '../../../lib/utils'
import { navigate } from 'gatsby'
import { BudgetSlideUpModal } from '../../budget'
import { AnnouncementModal } from '../../shared'
import moment from 'moment'
import GetIncomeMokuTip from '../../shared/GetIncomeMokuTip'
import { friendlyState } from '../../clearanceCertificate/MyClearanceCertificate/MyClearanceCertificate'


const MY_FINANCIAL_HEALTH = gql`{
  myClient {
    id
    income
    firstNames
    debtBreakdown {
      finalPaymentDate
      allDebt
      housingDebt
      vehicleDebt
      normalDebt
      compuScore
      affordability
      debtPaymentProjection {
        date
        payment
      }
      withDCPaymentProjection {
        payment
        payments {
          date
          payment
        }
        interestSaved
      }
      estimatedIncome
      hasDebtCounselling
      budget {
        description
        amount
      }
    }
    savings {
      id
      bucketInfo {
        amount
        targetAmount
      }
    }
    clearanceCertificates {
      id
      state
    }
  }
}`

const getSavingsSummary = (savings) => {
  if (!savings) {
    return {
      innerText: "No Savings",
      value: 0,
      color: "grey5",
      max: 10000
    }
  }

  const totalSaved = savings.bucketInfo.reduce((total,  b) => total + b.amount || 0, 0)
  const totalTarget = savings.bucketInfo.reduce((total,  b) => total + b.targetAmount || 0, 0)
  const percentage = totalSaved / (totalTarget || 1) * 100

  return {
    innerText: `Target: ${Math.round(totalTarget)}`,
    color: percentage < 30 ? "alertRed" : percentage < 70 ? "alertYellow" : "primary",
    value: totalSaved,
    max: totalTarget,
  }
}

const getClearanceCertificateSummary = (clearanceCertificates) => {
  const certificates = clearanceCertificates.filter(c => !['DC_LEAD', 'DC_CLIENT', 'CANCELLED'].includes(c.state))
  const lastOne = certificates[0]

  if (!lastOne) {
    return {}
  }

  return {
    id: lastOne.id,
    innerText: friendlyState[lastOne.state],
    color: "primary",
    value: 1,
    min: 0,
    max: 1,
  }
}

function MyFinancialHealth({ welcome = false }) {
  const { data, error, loading } = useQuery(MY_FINANCIAL_HEALTH)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [budgetOpen, setBudgetOpen] = useState(false)
  const [welcomeOpen, setWelcomeOpen] = useState(welcome)

  if (error) {
    return "An error occurred, please contact support"
  }

  console.log(error)

  if (loading) {
    return <ActivityIndicator />
  }

  const getMaxDate = da => {
    if (da) {
      const date = Math.max(...da.map(d => d.date))

      if (date === -Infinity) {
        return ""
      } else {
        return moment(date).format("MMM YYYY")
      }
    } else {
      return ""
    }
  }

  const prevCard = () => {
    setSelectedIndex((selectedIndex + 4) % 3)
  }

  const nextCard = () => {
    setSelectedIndex((selectedIndex + 1) % 3)
  }

  const maxY = useData && useData.budget ? Math.max(...useData.budget.map(d => d.amount)) : 0

  const useData = {
    ...((data && data.myClient && data.myClient.debtBreakdown) || {}),
    income: data && data.myClient && data.myClient.income,
    savings: data && data.myClient && data.myClient.savings,
    firstNames: data && data.myClient && data.myClient.firstNames,
    debtBreakdown: {
      home: (data && data.myClient && data.myClient.debtBreakdown.housingDebt)|| 0,
      education: 0,
      personal: (data && data.myClient && data.myClient.debtBreakdown.normalDebt) || 0,
      car:  (data && data.myClient && data.myClient.debtBreakdown.vehicleDebt) || 0,
      credit: 4800
    }
  } 

  const income = (useData.income ? useData.income : useData.estimatedIncome)
  const budget = income - useData.affordability
  const riskSummmary = getRiskProfile(useData)
  const affordabilitySummary = getAffordabilitySummary(useData.affordability, income)
  const savingsSummary = getSavingsSummary(useData.savings)
  const clearanceSummary = getClearanceCertificateSummary(data.myClient.clearanceCertificates, income)

  const hasClearanceCertificate = (data.myClient.clearanceCertificates || []).filter(d => !['DC_LEAD', 'DC_CLIENT', 'CANCELLED'].includes(d.state)).length > 0

  const hasDebt = useData.debtBreakdown && useData.debtBreakdown.allDebt && (useData.debtBreakdown.allDebt != 0)
  
  return <MyFinancialHealthContainer>
    <AnnouncementModal open={welcomeOpen} setOpen={setWelcomeOpen} title="You are registered! Welcome to Meerkat." description="We look forward helping you to do more with your money." closeTitle="Proceed" />
    <BudgetSlideUpModal open={budgetOpen} setOpen={setBudgetOpen} />
    <TitleContainer>
      <Text size={26} bold>My Financial Health</Text>
      <Text size={16} bold>Welcome, {useData.firstNames || ""}. Let’s keep you on track of your financial wellness.</Text>
    </TitleContainer>
    <SummaryItemsRow>
      <SummaryItemCard title="Monthly Income" description="" value={income ? printCurrency(income) : "Unknown"} color="text" />
      <SummaryItemCard title="My Savings" description="Helping you do more" value={printCurrency(savingsSummary.value)} color="text" onClick={() => navigate('/savings')} />
      <SummaryItemCard title="Debt Repayment" description={!useData.allDebt ? "No Debt" : `Debt settled by ${useData.hasDebtCounselling && useData.finalPaymentDate ? moment(useData.finalPaymentDate).format("MMM YYYY") : getMaxDate(useData.debtPaymentProjection)}`} value={printCurrency(useData.allDebt || 0)} color="text" onClick={() => navigate('/debt-repayments')} />
    </SummaryItemsRow>
    <p/>
    <GetIncomeMokuTip income={useData.income} estimatedIncome={useData.estimatedIncome}/>
    <p/>
    <CardRow>
      <Card onClick={() => { navigate('/debt') }}>
        <Text bold small>Credit Score</Text>
        <GraphContainer small>
          <PieGraph min={-350} max={750} {...riskSummmary} />
        </GraphContainer>
      </Card>
      <Card onClick={() => { navigate('/savings') }}>
        <Text bold small>My Savings Goals</Text>
        <GraphContainer small>
          <PieGraph min={0} {...savingsSummary} />
        </GraphContainer>
      </Card>
      <Card onClick={() => { navigate('/debt') }}>
        <Text bold small>My Affordability</Text>
        <GraphContainer small>
          <PieGraph value={useData.affordability} min={Math.min(useData.affordability, -1000)} max={Math.max(useData.affordability, income * 0.4)} {...affordabilitySummary} />
        </GraphContainer>
      </Card>
      <Card onClick={() => { setBudgetOpen(true) }}>
          <Text bold small>My Budget</Text>
          <GraphContainer small>
          <StackedBarGraph data={useData.budget ? [
            useData.budget.map((d, index) => ({ x: index + 1, y: d.amount, fill: theme.colors.primary })),
          ] : []}>
            <VictoryScatter data={[{ x: ((useData.budget || []).length + 1) / 2, y: maxY * 1.1 }]} dataComponent={<CustomPoint label={`${printCurrency(budget)} pm`} backgroundColor="primary" />} />
          </StackedBarGraph>
          </GraphContainer>
          <center><Text small>Set-up Budget</Text></center>
        </Card>
        {hasDebt ? <Card onClick={() => navigate('/debt-repayments') }>
          <Text bold small>My Debt Repayments</Text>
          <GraphContainer small>
            <BarGraph data={Object.keys(useData.debtBreakdown).map((category , index) => ({ x: index + 1, y: useData.debtBreakdown[category], fill: theme.colors[debtColors[category]] }))}>
              <VictoryScatter data={[{ x: 3, y: Math.max(...Object.keys(useData.debtBreakdown).map(category => useData.debtBreakdown[category])) * 1.05 }]} dataComponent={<CustomPoint label={printCurrency(useData.allDebt)} backgroundColor="alertRed" />} />
            </BarGraph>
          </GraphContainer>
          <center><Text small>View Debt Details</Text></center>
        </Card> : hasClearanceCertificate ? <Card onClick={() => { navigate(`/clearance-certificate/${clearanceSummary.id}`) }}>
        <Text bold small>My Clearance Certificate</Text>
        <GraphContainer small>
          <PieGraph {...clearanceSummary} />
        </GraphContainer>
      </Card> :<Card blank />}
    </CardRow>
    {/*<TitleContainer>
      <Text bold>Other Meerkat Products</Text>
      <Text>Explore our other tools and add them to your profile</Text>
      <SelectableItem title="My Debt" arrow />
      <SelectableItem title="My Funeral Policy" arrow />
      <SelectableItem title="Credit Life Insurance" arrow />
      <SelectableItem title="My Credit Report" arrow />
      <SelectableItem title="Finalise My Budget" arrow />
    </TitleContainer>
    <p />
    <Row>
      <Col>
        <Text bold>Who is Moku</Text>
        <Text small>Moku is here to help you on your financial wellbeing journey. Should you have a question, need advise or just curious, Moku is here to help you out.</Text>
      </Col>
      <Col centerVertical>
        <img src={mokuChat} alt="Chat with Moku" style={{ margin: "auto" }} />
      </Col>
    </Row>*/}
  </MyFinancialHealthContainer>

  

}

export default MyFinancialHealth