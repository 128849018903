import React, { useState } from 'react'
import { isNumber, isRequired, isValidRsaIdNumber, isValidEmail, isValidCellphoneNumber } from '../../../lib/validators'
import WizardModal from '../../shared/WizardModal'
import CompleteBlob from '../../shared/CompleteBlob'
import TermsAndConditionsLink from '../../shared/TermsAndConditionsLink'
import MokuNotePanel from '../../shared/MokuNotePanel'
import firebase from 'gatsby-plugin-firebase'
import ActivityIndicator from '../../generic/ActivityIndicator'
import gql from 'graphql-tag'
import { sittingOnMoney } from '../../../images'
import Text from '../../generic/Text'
import { ADD_LEAD, buildAddLeadMutationInput } from '../../../lib/contactForm'
import { useQuery } from 'react-apollo'
import ClearanceCertificateObligationDetails from '../ClearanceCertificateObligationDetails'

const MY_DETAILS = gql`{
  myClient {
    id
    idNumber
    cellNumbers
    email
  }
}`


// TODO: Share with GetCreditReport ...
const GET_CREDIT_REPORT = gql`query($idNumber:String!, $income: Float) {
  getChallenge(idNumber: $idNumber, income: $income, force: true) {
    error
    telephones
    employers
    accounts
  }
}`

const GET_CREDIT_REPORT_ACCESS = gql`query($idNumber: String!, $telephone: String, $employer: String, $account: String) {
  doChallenge(idNumber: $idNumber, telephone: $telephone, employer: $employer, account: $account)   {
    passedChallenge
    accessCode
  }
}`

const CHECK_DEBT_COUNSELLOR = gql`query($idNumber: String!, $accessCode: String) {
  getCreditRecord(idNumber: $idNumber, accessCode: $accessCode) {
    hasDebtCounselling
    hasJudgements
    meerkatIsDebtCounsellor
    accessCode
  }
}
`

const CREATE_CLEARANCE_CERTIFICATE = gql`
  mutation($clearanceCertificate: CreateClearanceCertificateInput, $client: CreateClientInput, $accessCode: String, $idDocumentId: String) {
    createSalesInteractionClearanceCertificate(clearanceCertificate: $clearanceCertificate, client: $client, accessCode: $accessCode, idDocumentId: $idDocumentId, salesInteraction: {}) {
      id
      certificateNumber
      accessCode
    }
  }
`

export const SIGN_CLEARANCE_CERTIFICATE = gql`
  mutation($clearanceCertificateId: String, $signature: String!, $accessCode: String, $secondaryApplicant: Boolean) {
    signClearanceCertificateDocuments(clearanceCertificateId: $clearanceCertificateId, signature: $signature, secondaryApplicant: $secondaryApplicant, accessCode: $accessCode) {
      forObligations {
        id
      }
      accessCode
    }
  }
`

// TODO - Add this as a shared component or prop on text
const PencilText = (props) => {
  console.log(props.style)
  return <Text {...props} style={{ ...(props.style || {}), fontFamily: "Nanum Pen Script, cursive"}} />
} 

// TODO - Add this as a shared component
function MokuTitle({ title, title2 }) {
  return <div style={{ width: "100%", textAlign: "center", marginBottom: "32px" }}>
    {title && <Text size={13} style={{ paddingLeft: "14px", paddingRight: "14px" }}>{title}</Text>}
    {title2 && <>{title && <div style={{ height: "8px" }} />}<Text size={26} bold style={{ paddingLeft: "14px", paddingRight: "14px" }}>{title2}</Text></>}
  </div>
}

/*
Credit Report Failure

Error: We have been unable to retrieve your credit report. The credit bureau is currently unavailable. Please try again later.
*/

const buildSteps = (setOpen, setQuestionData, setAccessCode, source) => {
  let accessCode

  return [{
    name: "init",
    noNext: true,
    nextAfterDelay: 10,
    nextSteps: ["step1"]
  }, {
    name: "step1",
    beforeStep: async () => {
      firebase.analytics().logEvent("CLEARANCE_CERTIFICATE_START");
    },
    title: "Clear My Name",
    title2: "Confirm your Personal Details",
    fieldNames: ["idNumber", "cellNumber", "whatsAppNumber","email", "acceptTerms"],
    nextTitle: "Clear My Name",
    component: () => <center><Text size="10" color="grey4">By accepting our Ts & Cs you consent to us accessing your credit report</Text></center>,
    // TODO: Add Call Me Back
    nextSteps: ({ signedIn }) => signedIn ? ["checkDebtCounselling"] : ["verificationStep1"]
  }, {
    name: "verificationStep1",
    beforeStep: async ({ apolloClient, chatState }) => {
      firebase.analytics().logEvent("CLEARANCE_CERTIFICATE_VERIFICATION_START");
      const { idNumber, income } = chatState
      const result = await apolloClient.query({ query: GET_CREDIT_REPORT, variables: { idNumber, income } })
      setQuestionData(result.data.getChallenge)
    },
    title: "Credit Report - ID validation (1 of 3)",
    title2: "Please answer the following questions to validate your identity",
    fieldNames: ["telephone"],
    nextSteps: ["verificationStep2"]
  }, {
    name: "verificationStep2",
    title: "Credit Report - ID validation (2 of 3)",
    title2: "Please answer the following questions to validate your identity",
    fieldNames: ["employer"],
    nextSteps: ["verificationStep3"]
  },
  {
    name: "verificationStep3",
    title: "Credit Report - ID validation (3 of 3)",
    title2: "Please answer the following questions to validate your identity",
    fieldNames: ["account"],
    nextSteps: ["performVerification"]
  },
  {
    name: "performVerification",
    beforeStep: async ({ apolloClient, chatState }) => {
      const { idNumber, telephone, employer, account } = chatState
      const result = await apolloClient.query({ query: GET_CREDIT_REPORT_ACCESS, variables: { idNumber, telephone, employer, account } })

      console.log(result)

      if (result.data.doChallenge && result.data.doChallenge.passedChallenge) {
        accessCode = result.data.doChallenge.accessCode
      } else {
        accessCode = null
      }

      if (accessCode) {
        firebase.analytics().logEvent("CLEARANCE_CERTIFICATE_VERIFICATION_SUCCE");
      } else {
        firebase.analytics().logEvent("CLEARANCE_CERTIFICATE_VERIFICATION_ERROR");
      }
      setAccessCode(accessCode)
    },
    component: ActivityIndicator,
    nextAfterDelay: 1000,
    noNext: true,
    nextSteps: () => accessCode ? ["checkDebtCounselling"] : ["error"]
  },
  {
    name: "getIdDocument",
    beforeStep: async () => {
      firebase.analytics().logEvent("CLEARANCE_CERTIFICATE_GET_ID_DOCUMENT");
    },
    title: "Clear My Name",
    title2: "Upload your ID Document",
    title3: "We need your ID Document to be able to process your application. You may upload this later",
    fieldNames: ["idDocumentId"],
    nextTitle: "Continue",
    nextSteps: ["getSignature"]
  },
  {
    name: "getSignature",
    beforeStep: async ({ apolloClient, changeFieldValue, chatState }) => {
      const { idNumber, cellNumber, email, acceptTerms, idDocumentId, whatsAppNumber } = chatState

      firebase.analytics().logEvent("CLEARANCE_CERTIFICATE_GET_SIGNATURE");

      const result = await apolloClient.mutate({ mutation: CREATE_CLEARANCE_CERTIFICATE, variables: { client: {
        idNumber,
        cellNumbers: [cellNumber],
        email,
        acceptedTerms: acceptTerms,
        whatsAppNumber
      },
      idDocumentId,
      accessCode, clearanceCertificate: source ? {
        referredFrom: source } : null } })
      
      changeFieldValue('clearanceCertificateId', result.data.createSalesInteractionClearanceCertificate.id)
      changeFieldValue('certificateNumber', result.data.createSalesInteractionClearanceCertificate.certificateNumber)
      accessCode = result.data.createSalesInteractionClearanceCertificate.accessCode
      setAccessCode(accessCode)
    },
    title: "Clear My Name",
    title2: "Add your signature to make it official",
    title3: <>
      You will be signing the following documents that we use to clear your name. We will fill them in for you.
      <p />
      <ul style={{ textAlign: "left", fontSize: "12px", margin: 0 }}>
        <li>Application Form</li>
        <li>Consent to Transfer of Debt Counsellor so we can issue a certificate</li>
        <li>Power of Attorney to act on your behalf</li>
      </ul>
    </>,
    fieldNames: ["signature"],
    nextSteps: ["doSign"]
  },
  {
    beforeStep: async ({ apolloClient, chatState, changeFieldValue }) => {
      const { signature, clearanceCertificateId } = chatState
      const result = await apolloClient.mutate({ mutation: SIGN_CLEARANCE_CERTIFICATE, variables: { signature, clearanceCertificateId, accessCode } })
      console.log(result)

      firebase.analytics().logEvent("CLEARANCE_CERTIFICATE_GET_LOAN_DETAILS");

      accessCode = result.data.signClearanceCertificateDocuments.accessCode

      changeFieldValue("noLoans", result.data.signClearanceCertificateDocuments.forObligations.length === 0)

      setAccessCode(accessCode)
    },
    name: "doSign",
    noNext: true,
    nextAfterDelay: 1000,
    nextSteps: ({ noLoans, signedIn }) => noLoans ? [`completedSale${signedIn ? "SignedIn" : ""}`] : ["checkLoans"]
  },
  {
    name: "checkLoans",
    title: "Clear My Name",
    title2: "Details on Your Loans",
    title3: <MokuNotePanel title="This is what we know" description="We have contacted the credit bureaux and received the following details. We need to ensure that we have all the correct details of the included loans" />,
    component: ({ chatState }) => <ClearanceCertificateObligationDetails chatState={chatState} accessCode={accessCode} setAccessCode={newCode => { accessCode = newCode; setAccessCode(newCode) }} />,
    nextSteps: ({ signedIn }) => [`completedSale${signedIn ? "SignedIn" : ""}`]
  },
  {
    name: "checkActive",
    title: "",
    title2: "",
    nextTitle: "C",
    nextSteps: ({ signedIn }) => [`completedSale${signedIn ? "SignedIn" : ""}`]
  },
  {
    name: "completedSale",
    beforeStep: ({ changeFieldValue }) => {
      firebase.analytics().logEvent("CLEARANCE_CERTIFICATE_COMPLETED_SALE");
      changeFieldValue("wizardSection", "Done")
    },
    text: ({ certificateNumber }) => <>
      <div style={{ position: "absolute", left: "calc(79px + calc((100% - 360px) / 2)" }}>
        <PencilText primary size={34} style={{ marginBottom: "20px", height: "30px", lineHeight: "30px", transform: "rotate(-7.69deg)" }}>Nearly!</PencilText>
        <div style={{ marginLeft: "40px" }}><PencilText primary size={28} style={{ width: "13px", height: "30px", transform: "rotate(90deg)", lineHeight: "30px" }}>{"<"}</PencilText></div>
      </div>
      <div style={{ height: "18px"}} />
      <MokuTitle title2="We've &nbsp; got your name cleared!" />
      <center>
      <Text size={16} style={{ padding: "14px" }}>You have now completed your application for your clearance certificate. We will begin processing your application. Please note that this can take up to two weeks. You will receive your certificate once the process is completed and payment has been received.</Text>
      <p /> 
      <Text bold size={16} style={{ padding: "14px" }}>Your certificate reference number is {certificateNumber}</Text>
      <p />
      </center>
    </>,
    nextButtonsInColumn: true,
    nextButtons: [{ label: "Register to Track My Status", nextSteps: ["signup"] }, { label: "Finish", nextSteps: ["done"], buttonProps: { inverted: true , noBorder: true } }],
  },
  {
    name: "checkDebtCounselling",
    beforeStep: async ({ apolloClient, chatState, changeFieldValue }) => {
      const { idNumber } = chatState
      const result = await apolloClient.query({ query: CHECK_DEBT_COUNSELLOR, variables: { idNumber, accessCode } })
      console.log(result)

      changeFieldValue("hasDebtCounselling", result.data.getCreditRecord.hasDebtCounselling)
      changeFieldValue("hasJudgements", result.data.getCreditRecord.hasJudgements)
      changeFieldValue("meerkatIsDC", result.data.getCreditRecord.meerkatIsDebtCounsellor)

      accessCode = result.data.getCreditRecord.accessCode
      setAccessCode(accessCode)
    },
    nextAfterDelay: 1000,
    noNext: true,
    nextSteps: ({ hasDebtCounselling, hasJudgements, meerkatIsDC }) => hasDebtCounselling ? (meerkatIsDC ? ["meerkatIsDC"] : (hasJudgements ? ["hasJudgements"] : ["getIdDocument"])) : ["notInDebtCounselling"]
  },
  {
    name: "hasJudgements",
    beforeStep: async ({ changeFieldValue, chatState, apolloClient }) => {
      changeFieldValue("wizardSection", "Done")

      firebase.analytics().logEvent("CLEARANCE_CERTIFICATE_HAS_JUDGEMENTS");

      const { idNumber, cellNumber, email, acceptTerms, idDocumentId, whatsAppNumber } = chatState

      const result = await apolloClient.mutate({ mutation: CREATE_CLEARANCE_CERTIFICATE, variables: { client: {
        idNumber,
        cellNumbers: [cellNumber],
        email,
        acceptedTerms: acceptTerms,
        whatsAppNumber
      },
      idDocumentId,
      accessCode, clearanceCertificate: source ? {
        referredFrom: source } : null } })
      
      changeFieldValue('clearanceCertificateId', result.data.createSalesInteractionClearanceCertificate.id)
      changeFieldValue('certificateNumber', result.data.createSalesInteractionClearanceCertificate.certificateNumber)
      accessCode = result.data.createSalesInteractionClearanceCertificate.accessCode
      setAccessCode(accessCode)
    },
    text: ({ certificateNumber }) => <>
      <div style={{ height: "18px"}} />
      <MokuTitle title2="Your name is not yet clear" />
      <center>
      <Text size={16} style={{ padding: "14px" }}>We have retrieved your credit report and see that you have a judgement. We are unable to proceed with your clearance certificate application until this has been paid.<p />One of our consultants will contact you to discuss next steps. Register now to check out your financial health</Text>
      <p />
      </center>
    </>,
    nextButtonsInColumn: true,
    nextButtons: [{ label: "Register", nextSteps: ["signup"] }, { label: "Finish", nextSteps: ["done"], buttonProps: { inverted: true , noBorder: true } }],
  },
  {
    name: "meerkatIsDC",
    beforeStep: async ({ changeFieldValue, chatState, apolloClient }) => {
      changeFieldValue("wizardSection", "Done")

      firebase.analytics().logEvent("CLEARANCE_CERTIFICATE_MEERKAT_IS_DC");

      const { idNumber, cellNumber, email, acceptTerms, idDocumentId, whatsAppNumber } = chatState

      const result = await apolloClient.mutate({ mutation: CREATE_CLEARANCE_CERTIFICATE, variables: { client: {
        idNumber,
        cellNumbers: [cellNumber],
        email,
        acceptedTerms: acceptTerms,
        whatsAppNumber
      },
      idDocumentId,
      accessCode, clearanceCertificate: source ? {
        referredFrom: source } : null } })
      
      changeFieldValue('clearanceCertificateId', result.data.createSalesInteractionClearanceCertificate.id)
      changeFieldValue('certificateNumber', result.data.createSalesInteractionClearanceCertificate.certificateNumber)
      accessCode = result.data.createSalesInteractionClearanceCertificate.accessCode
      setAccessCode(accessCode)
    },
    text: ({ certificateNumber }) => <>
      <div style={{ height: "18px"}} />
      <MokuTitle title2="We see you are one of us" />
      <center>
      <Text size={16} style={{ padding: "14px" }}>You are under debt review with Meerkat. Your clearance certificate is provided once your loans have been paid up.<p />One of our consultants will contact you to discuss the next steps. Register now to check out your financial health</Text>
      <p />
      </center>
    </>,
    nextButtonsInColumn: true,
    nextButtons: [{ label: "Register", nextSteps: ["signup"] }, { label: "Finish", nextSteps: ["done"], buttonProps: { inverted: true , noBorder: true } }],
  },
  {
    name: "notInDebtCounselling",
    beforeStep: async ({ changeFieldValue, chatState, apolloClient  }) => {
      changeFieldValue("wizardSection", "Done")

      firebase.analytics().logEvent("CLEARANCE_CERTIFICATE_NOT_IN_DC");

      const { idNumber, cellNumber, email, acceptTerms, idDocumentId, whatsAppNumber } = chatState

      const result = await apolloClient.mutate({ mutation: CREATE_CLEARANCE_CERTIFICATE, variables: { client: {
        idNumber,
        cellNumbers: [cellNumber],
        email,
        acceptedTerms: acceptTerms,
        whatsAppNumber
      },
      idDocumentId,
      accessCode,
      clearanceCertificate: source ? {
      referredFrom: source } : null } })
      
      changeFieldValue('clearanceCertificateId', result.data.createSalesInteractionClearanceCertificate.id)
      changeFieldValue('certificateNumber', result.data.createSalesInteractionClearanceCertificate.certificateNumber)
      accessCode = result.data.createSalesInteractionClearanceCertificate.accessCode
      setAccessCode(accessCode)
    },
    text: ({ certificateNumber }) => <>
      <div style={{ height: "18px"}} />
      <MokuTitle title2="Your name is clear" />
      <center>
      <Text size={16} style={{ padding: "14px" }}>Great news! You are not flagged as under debt review on the credit bureaux which means your name is clear.<p />Let’s help you stay that way. Register with Meerkat and get started with an emergency fund from as little as R25pm and no longer rely on debt.</Text>
      <p />
      </center>
    </>,
    nextButtonsInColumn: true,
    nextButtons: [{ label: "Register", nextSteps: ["signup"] }, { label: "Finish", nextSteps: ["done"], buttonProps: { inverted: true , noBorder: true } }],
  },
  { 
    name: "completedSaleSignedIn",
    beforeStep: ({ changeFieldValue }) => {
      firebase.analytics().logEvent("CLEARANCE_CERTIFICATE_COMPLETED_SALE");
      changeFieldValue("wizardSection", "Done")
    },
    text: ({ certificateNumber }) => <>
      <div style={{ position: "absolute", left: "calc(99px + calc((100% - 360px) / 2)" }}>
        <PencilText primary size={34} style={{ marginBottom: "20px", marginTop: "-14px", height: "30px", lineHeight: "30px", transform: "rotate(-7.69deg)" }}>Nearly!</PencilText>
        <div style={{ marginLeft: "40px" }}><PencilText primary size={28} style={{ width: "13px", height: "30px", transform: "rotate(90deg)", lineHeight: "30px" }}>{"<"}</PencilText></div>
      </div>
      <div style={{ height: "18px"}} />
      <MokuTitle title2="We've &nbsp; got your name cleared!" />
      <center>
      <Text size={16} style={{ padding: "14px" }}>You have now completed your application for your clearance certificate. We will begin processing your application. You have now completed your application for your clearance certificate. We will begin processing your application. Please note that this can take up to two weeks. You will receive your certificate once the process is completed and payment has been received.</Text>
      <p /> 
      <Text bold size={16} style={{ padding: "14px" }}>Your certificate reference number is {certificateNumber}</Text>
      <p />
      </center>
    </>,
    nextTitle: "Finish",
    nextSteps: ["done"]
  },
  {
    name: "error",
    title: "Clear My Name",
    title2: <CompleteBlob image={sittingOnMoney} title="Oops!" description="Something went wrong." />,
    nextButtonsInColumn: true,
    nextButtons: [{ label: "Let's try again", nextSteps: ["step1"] }, { label: "Nevermind, I'm out", onClick: () => setOpen(false), noNextStep: true }]
  }, {
    name: "signup",
    beforeStep: async ({ chatState }) => {
      const { clientId } = chatState
      firebase.analytics().logEvent("CLEARANCE_CERTIFICATE_REGISTER", {})
      window.location=`https://${process.env.HOSTNAME}/sign-in?redirect=/clearance-certificate&clientId=${clientId}`
    }
  }, {
    name: "callback",
    title: "Clear My Name",
    title2: "Call Back Request",
    fieldNames: ["firstName", "surname", "cellNumber", "email"],
    beforeStep: async () => {
      firebase.analytics().logEvent("CLEARANCE_CERTIFICATE_CALLBACK_REQUEST", {})
    },
    nextSteps: ["sendCallback"],
    showBack: true
  }, {
    name: "sendCallback",
    beforeStep: async ({ apolloClient, chatState }) => {
      firebase.analytics().logEvent("CLEARANCE_CERTIFICATE_CALLBACK_REQUEST_SENT", {})
      apolloClient.mutate({ mutation: ADD_LEAD, variables: buildAddLeadMutationInput({
        firstName: chatState.firstName,
        surname: chatState.surname,
        cellNumber: chatState.cellNumber,
        email: chatState.email,
        talkAbout: "Clearance Certificate",
        message: "Quick Quote Callback Request",
        routing: "mcc"
      }) })
      setOpen(false)
    },
    noNext: true
  }, {
    name: "done",
    beforeStep: () => setOpen(false)
  }]
}

const fields = {
  idNumber: {
    type: "text",
    label: "Your ID Number",
    placeholder: "Enter 13 digit ID number",
    validate: [isValidRsaIdNumber, isRequired]
  },
  email: {
    label: "Email",
    validate: [isRequired, isValidEmail]
  },
  cellNumber: {
    label: "Cell Number",
    validate: [isRequired, isValidCellphoneNumber]
  },
  telephone: {
    label: "Do you recognise one of these phone numbers as yours?",
    type: "radiogroup",
    options: [],
    validate: [isRequired]
  },
  income: {
    label: "What is your net-income?",
    type: "currency",
    validate: [isRequired, isNumber],
  },
  employer: {
    label: "Who is your current / previous employer?",
    type: "radiogroup",
    options: [],
    validate: [isRequired]
  },
  account: {
    label: "Do you recognise any of these accounts?",
    type: "radiogroup",
    options: [],
    validate: [isRequired]
  },
  signature: {
    type: "signature",
    validate: [isRequired]
  },
  whatsAppNumber:{
    type: "whatsappOpt",
    cellNumberField: "cellNumber" ,
    validate: [isValidCellphoneNumber]

  },
  acceptTerms: {
    type: "checkbox",
    label: <>Accept our <TermsAndConditionsLink>Terms of Services and Privacy Policy</TermsAndConditionsLink></>,
    validate: value => value === true ? undefined : "You need to accept terms and conditions to continue"
  },
  idDocumentId: {
    type: "fileupload",
    label: "ID Document",
    prompt: "Upload your Identity Document"
  }
}

function ClearanceCertificateQuickQuote({ open, setOpen }) {
  const [accessCode, setAccessCode] = useState(null)
  const { data, loading, error } = useQuery(MY_DETAILS)
  let { myClient } = data || {}
  if (!myClient) {
    myClient = {}
  }

  if (loading) { return <div /> }

  const source = window && (new URLSearchParams(window.location.search).get("utm_source"))
  
  const setQuestionData = ({ telephones, accounts, employers }) => {
    fields.telephone.options = telephones
    fields.account.options = accounts
    fields.employer.options = employers
  }

  return <WizardModal
    name="clearanceCertificateQQ"
    open={open}
    setOpen={setOpen}
    steps={buildSteps(setOpen, setQuestionData, setAccessCode, source)}
    initialValues={{ currentStep: 0, acceptTerms: true, signedIn: !!(myClient && myClient.idNumber), idNumber: myClient.idNumber, email: myClient.email, idType: myClient.idType, cellNumber: myClient.cellNumbers && myClient.cellNumbers[0], whatsAppNumber: myClient.cellNumbers && myClient.cellNumbers[0] }}
    fields={fields}
  />
}

export default ClearanceCertificateQuickQuote
